<template>
  <div>
    <AddBloodActivity
      title="Penimbangan"
      processActivity="addProcessWeighing"
    />
  </div>
</template>

<script>
import AddBloodActivity from "../components/AddBloodActivity.vue";

export default {
  name: "Penimbangan",
  components: {
    AddBloodActivity,
  },
};
</script>
